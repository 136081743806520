exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-orig-index-tsx": () => import("./../../../src/pages/orig-index.tsx" /* webpackChunkName: "component---src-pages-orig-index-tsx" */),
  "component---src-pages-package-comparison-tsx": () => import("./../../../src/pages/package-comparison.tsx" /* webpackChunkName: "component---src-pages-package-comparison-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-solutions-tsx": () => import("./../../../src/pages/solutions.tsx" /* webpackChunkName: "component---src-pages-solutions-tsx" */)
}

